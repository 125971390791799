<template>
  <div class="theme-background">
    <!-- Title. -->
    <content-title :subtitle="true">
      <template slot="title">
        <h3>Medical Conditions</h3>
      </template>
      <template slot="subtitle">
        <p>
          Please check off any that apply, or add any additional information in the other
          section.<br />
          This information will help the doctor provide you with appropritate treatment options.
        </p>
      </template>
    </content-title>

    <!-- Selectable medical conditions. -->
    <div>
      <v-row style="max-width: 1000px">
        <v-col>
          <h3 v-if="conditions.length === 0">No conditions found.</h3>
          <v-checkbox
            v-for="(condition, i) in conditions"
            v-model="condition.status"
            :key="i"
            :label="condition.name"
            hide-details
            class="condition"
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>
    <custom-space></custom-space>
    <div>
      <span>Other:</span><br />
      <v-textarea
        ref="customCondition"
        v-model="customCondition"
        style="max-width: 800px"
        outlined
        no-resize
        :disabled="savedCondition"
        rows="5"
        placeholder="Type here"
        :rules="validate({ customCondition: this.customCondition })"
      >
      </v-textarea>
    </div>
    <div>
      <theme-button btnColor="blue" v-if="!savedCondition" @click="addCondition" />

      <theme-button
        btnColor="blue"
        v-if="savedCondition"
        @click="() => (this.savedCondition = false)"
        >Edit</theme-button
      >
    </div>
  </div>
</template>
<style scoped>
.condition {
  width: 30%;
  float: left;
}

@media all and (max-width: 600px) {
  .condition {
    width: 100%;
  }
}
</style>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CustomSpace from './CustomSpace.vue';
import ContentTitle from './ContentTitle.vue';
import ThemeButton from './ThemeButton.vue';
import joiSchema from '../utils/joi';

export default {
  components: {
    CustomSpace,
    ContentTitle,
    ThemeButton,
  },
  name: 'MedicalConditions',
  data() {
    return {
      savedCondition: false,
      customCondition: '',
      conditions: [],
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions(['updateUser']),
    ...mapMutations(['saveUser']),
    validate(toValidate) {
      // Does not work as vuex action.
      const { error } = joiSchema.validate(toValidate);
      if (error) {
        return [error.message];
      }
      return [true];
    },
    addCondition() {
      if (this.$refs.customCondition.validate()) {
        this.savedCondition = true;
        this.save();
      }
    },
    refresh() {
      if (this.user.metaData) {
        const conditions = this.user.metaData.other.medicalConditions;
        if (conditions[conditions.length - 1].custom) {
          [this.customCondition] = conditions.splice(conditions.length - 1, 1);
          this.customCondition = this.customCondition.name;
          this.savedCondition = true;
        }
        this.conditions = conditions;
      }
    },
    save() {
      const conditions = this.conditions.slice();
      conditions.push({
        status: false,
        custom: true,
        name: this.customCondition,
      });

      this.saveUser({
        metaData: {
          ...this.user.metaData,
          other: {
            ...this.user.metaData.other,
            medicalConditions: conditions,
          },
        },
      });

      this.updateUser();
    },
  },
  mounted() {
    this.refresh();
  },
  watch: {
    conditions: {
      handler() {
        this.save();
      },
      deep: true,
    },
  },
};
</script>
