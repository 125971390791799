<template>
  <v-btn
    :icon="$vuetify.breakpoint.xs"
    :class="btnColor ? btnColor : 'normal'"
    elevation="3"
    :width="$vuetify.breakpoint.xs ? '45px' : '200px'"
    :to="link"
    contained
  >
    <slot></slot>
  </v-btn>
</template>
<style>
.normal {
  background-color: var(--v-primary-darken1) !important;
}

.blue {
  background-color: var(--v-info-base) !important;
  color: white !important;
}

.red {
  background-color: var(--v-error-base) !important;
  color: white !important;
}
</style>
<script>
export default {
  name: 'ThemeButton',
  props: ['link', 'btnColor'],
};
</script>
