<template>
  <div class="theme-background">
    <!-- Title. -->
    <content-title :subtitle="true">
      <template slot="title">
        <h3>Medications</h3>
      </template>
      <template slot="subtitle">
        <p>
          If you take any medications, vitamins or herbal suppliments, add them here for the doctor
          to reference.<br />
          Enter the name, strength and directions as listed on your medication bottle as best you
          can.
        </p>
      </template>
    </content-title>

    <v-row justify="space-between">
      <!-- Add medication form. -->
      <v-col cols="12" lg="4">
        <div class="add-medication ma-auto">
          <v-form class="medication-form ma-auto" ref="form" v-if="$vuetify.breakpoint.xs">
            <v-text-field outlined v-model="medicationName" placeholder="Name"></v-text-field>
            <v-text-field
              outlined
              v-model="strength"
              placeholder="Strength (100mg or g)"
              :rules="validate({ medicationStrength: this.strength })"
            ></v-text-field>
            <v-text-field
              outlined
              v-model="directions"
              placeholder="Directions (1 tablet daily)"
            ></v-text-field>
          </v-form>
          <v-form class="ma-auto medication-form" ref="form" v-if="!$vuetify.breakpoint.xs">
            <v-row>
              <v-col>
                <span>Name of Medication</span><br />
                <v-text-field
                  outlined
                  v-model="medicationName"
                  placeholder="Type here"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>Strength (in g or mg)</span><br />
                <v-text-field
                  outlined
                  v-model="strength"
                  placeholder="100mg"
                  style="width: 50%"
                  :rules="validate({ medicationStrength: this.strength })"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>Directions</span><br />
                <v-text-field
                  outlined
                  v-model="directions"
                  placeholder="1 tablet once daily"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <custom-space amt="2"></custom-space>
        <div class="text-center">
          <v-btn
            v-if="!editing"
            large
            contained
            elevation="3"
            class="theme-button"
            @click="addMedication"
            ><v-icon before>mdi-plus </v-icon> Add to my list</v-btn
          >
          <v-btn v-if="editing" large rounded class="theme-button" @click="saveMedication"
            >Save</v-btn
          >
        </div>
      </v-col>

      <!-- Medication tables. -->
      <v-col cols="12" lg="6">
        <v-tabs
          background-color="transparent !important"
          centered
          right
          color="var(--v-secondary-base)"
        >
          <v-tab active-class="active-tab" style="min-width: 100px" key="current">
            Current Medications
          </v-tab>
          <v-tab
            v-if="!$vuetify.breakpoint.xs"
            style="width: 100%; min-width: 0px; max-width: 100%"
            disabled
            key="spacer"
          ></v-tab>
          <v-tab active-class="active-tab" style="min-width: 100px" key="archived">
            Archived
          </v-tab>
          <v-tab-item key="current">
            <custom-space></custom-space>
            <table class="medication-table theme-table-one">
              <thead class="text-left">
                <tr class="theme-table-one">
                  <th width="50px" class="theme-table-head-one">Medication</th>
                  <th width="50px" class="theme-table-head-one">Strength</th>
                  <th class="theme-table-head-one">Directions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="med in medications" :key="med.id" class="theme-table-one">
                  <td>{{ med.name }}</td>
                  <td>{{ med.strength }}</td>
                  <td>
                    <div>
                      <div style="float: left; margin-top: 10px">{{ med.directions }}</div>

                      <!-- Edit/Delete/Archive menu. -->
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            style="float: right"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            width="45px"
                            :style="
                              Object.keys(med).length === 0 ? { opacity: '0' } : { opacity: '1' }
                            "
                            ><v-icon>mdi-pencil</v-icon></v-btn
                          >
                        </template>
                        <v-list>
                          <v-list-item-group>
                            <v-list-item>
                              <v-list-item-title @click="archiveMedication(med.id)"
                                >Archive</v-list-item-title
                              >
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title @click="updateMedication(med.id)"
                                >Edit</v-list-item-title
                              >
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title @click="deleteMedication(med.id)"
                                >Delete</v-list-item-title
                              >
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-tab-item>
          <v-tab-item v-if="!$vuetify.breakpoint.xs" key="spacer"></v-tab-item>
          <v-tab-item key="archived">
            <custom-space></custom-space>
            <table class="medication-table theme-table-two">
              <thead class="text-left">
                <tr class="theme-table-two">
                  <th width="50px" class="theme-table-head-two">Medication</th>
                  <th width="50px" class="theme-table-head-two">Strength</th>
                  <th class="theme-table-head-two">Directions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="med in archivedMedications" :key="med.id" class="theme-table-two">
                  <td>{{ med.name }}</td>
                  <td>{{ med.strength }}</td>
                  <td>
                    <div>
                      <div style="float: left; margin-top: 10px">{{ med.directions }}</div>

                      <!-- Edit/Delete/Unarchive menu. -->
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            style="float: right"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            width="45px"
                            :style="
                              Object.keys(med).length === 0 ? { opacity: '0' } : { opacity: '1' }
                            "
                            ><v-icon>mdi-pencil</v-icon></v-btn
                          >
                        </template>
                        <v-list>
                          <v-list-item-group>
                            <v-list-item>
                              <v-list-item-title @click="unarchiveMedication(med.id)"
                                >Unarchive</v-list-item-title
                              >
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title @click="updateMedication(med.id)"
                                >Edit</v-list-item-title
                              >
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title @click="deleteMedication(med.id)"
                                >Delete</v-list-item-title
                              >
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.medication-form {
  margin-top: 120px !important;
  max-width: 90%;
  max-height: 90%;
}

.add-medication {
  background-image: url('../images/medication.png');
  background-size: 100% 100%;
  width: 400px;
  height: 550px;
  padding: 20px;
}

@media all and (max-width: 600px) {
  .add-medication {
    width: 100%;
    height: 350px;
  }

  .medication-form {
    margin-top: 85px !important;
  }
}

.active-tab {
  color: #000;
}
.medication-table {
  width: 100%;
}
</style>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import CustomSpace from './CustomSpace.vue';
import ContentTitle from './ContentTitle.vue';
import joiSchema from '../utils/joi';

export default {
  components: {
    CustomSpace,
    ContentTitle,
  },
  name: 'Prescriptions',
  data() {
    return {
      lastId: 0,
      editDetails: {},
      editing: false,
      directions: '',
      medicationName: '',
      strength: '',
      medications: [],
      archivedMedications: [],
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions(['updateUser']),
    ...mapMutations(['saveUser']),
    validate(toValidate) {
      // Does not work as vuex action.
      const { error } = joiSchema.validate(toValidate);
      if (error) {
        return [error.message];
      }
      return [true];
    },
    addMedication() {
      if (this.$refs.form.validate()) {
        this.lastId += 1;
        this.medications.unshift({
          id: this.lastId,
          name: this.medicationName || 'Not provided.',
          strength: this.strength || 'Not provided.',
          directions: this.directions || 'Not provided.',
        });
      }
    },
    updateMedication(id) {
      this.editing = true;
      this.editDetails = this.getObjectIndexById(id);

      const { index, archived } = this.editDetails;
      const toUpdate = archived ? this.archivedMedications[index] : this.medications[index];

      this.medicationName = toUpdate.name;
      this.strength = toUpdate.strength;
      this.directions = toUpdate.directions;
    },
    saveMedication() {
      if (this.$refs.form.validate()) {
        this.editing = false;

        const { index, archived } = this.editDetails;
        const toUpdate = archived ? this.archivedMedications[index] : this.medications[index];

        toUpdate.name = this.medicationName || 'Not provided.';
        toUpdate.strength = this.strength || 'Not provided.';
        toUpdate.directions = this.directions || 'Not provided.';

        this.medicationName = '';
        this.strength = '';
        this.directions = '';
      }
    },
    deleteMedication(id) {
      const { index, archived } = this.getObjectIndexById(id);

      if (archived) {
        this.archivedMedications.splice(index, 1);
      } else {
        this.medications.splice(index, 1);
      }
    },
    archiveMedication(id) {
      const { index } = this.getObjectIndexById(id);

      this.archivedMedications.unshift(this.medications[index]);
      this.medications.splice(index, 1);
    },
    unarchiveMedication(id) {
      const { index } = this.getObjectIndexById(id);

      this.medications.unshift(this.archivedMedications[index]);
      this.archivedMedications.splice(index, 1);
    },
    getObjectIndexById(id) {
      for (let i = 0; i < this.medications.length; i += 1) {
        if (this.medications[i].id === id) {
          return { archived: false, index: i };
        }
      }
      for (let i = 0; i < this.archivedMedications.length; i += 1) {
        if (this.archivedMedications[i].id === id) {
          return { archived: true, index: i };
        }
      }

      return undefined;
    },
    getMostRecentId() {
      let id = 0;
      this.medications.forEach((med) => {
        if (med.id > id) id = med.id;
      });
      this.archivedMedications.forEach((med) => {
        if (med.id > id) id = med.id;
      });
      return id;
    },
    refresh() {
      if (this.user.metaData) {
        this.medications = this.user.metaData.other.medications.current;
        this.archivedMedications = this.user.metaData.other.medications.archived;
        this.lastId = this.getMostRecentId();
      }
    },
    save() {
      this.saveUser({
        metaData: {
          ...this.user.metaData,
          other: {
            ...this.user.metaData.other,

            medications: {
              current: this.medications,
              archived: this.archivedMedications,
            },
          },
        },
      });
      this.updateUser();
    },
    padTable(table) {
      if (table.length < 5) {
        for (let i = 5; i > table.length; i -= 1) {
          table.push({});
        }
      } else if (table.length >= 5) {
        for (let i = 5; i < table.length; i += 1) {
          if (Object.keys(table[i]).length === 0) {
            table.splice(i, 1);
          }
        }
      }
    },
  },
  mounted() {
    this.refresh();
  },
  watch: {
    medications: {
      handler() {
        this.save();
        this.padTable(this.medications);
      },
      deep: true,
    },
    archivedMedications: {
      handler() {
        this.save();
        this.padTable(this.archivedMedications);
      },
      deep: true,
    },
  },
};
</script>
