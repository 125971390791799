<template>
  <!-- Container for all content displayed not in the header or in the side bar. -->
  <div class="content-container">
    <div class="content-scroll">
      <slot></slot>
    </div>
  </div>
</template>
<style scoped>
.content-container {
  border-radius: 15px;
  overflow: hidden;
  height: 100%;
}

.content-scroll {
  overflow-y: auto;
  overflow-x: auto;
  height: inherit;
  width: inherit;
}
@media all and (max-width: 1264px) {
  .content-container {
    width: 100vw;
  }
  .content-scroll {
    padding: 16px;
    padding-bottom: 64px;
  }
}
@media all and (min-width: 1264px) {
  .content-container {
    width: 100%;
  }
  .content-scroll {
    padding: 48px;
  }
}
</style>
<script>
export default {
  name: 'ContentContainer',
  data() {
    return {};
  },
};
</script>
