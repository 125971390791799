import joi from 'joi';
import joiDate from '@hapi/joi-date';
import moment from 'moment';

const Joi = joi.extend(joiDate);

// Provides schema rules and custom error messages for every
// validation in the client.

const joiSchema = Joi.object({
  firstName: Joi.string().min(2).max(30).messages({
    'string.empty': 'First name cannot be empty.',
    'string.min': 'First name must be longer than {#limit} characters.',
    'string.max': 'First name can not be longer then {#limit} characters',
  }),
  lastName: Joi.string().min(2).max(30).messages({
    'string.empty': 'Last name cannot be empty.',
    'string.max': 'Last name can not be longer then {#limit} characters',
    'string.min': 'Last name must be longer than {#limit} characters.',
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({
      'string.empty': 'Email cannot be empty.',
      'string.email': 'Must be a valid email.',
    }),
  phoneNumber: Joi.string()
    .regex(/^(1[\s.-])?(\(?)\d{3}(\)?)[\s.-]\d{3}[\s.-]\d{4}$/)
    .messages({
      'string.pattern.base': 'Must be a valid phone number.',
      'string.empty': 'Phone number cannot be empty.',
    }),
  password: Joi.string()
    .min(8)
    .max(64)
    .regex(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,64}$/)
    .messages({
      'string.pattern.base': 'A secure password needs one captial, one lowercase and one number.',
      'string.empty': 'Password cannot be empty.',
      'string.min': 'Password must be at least {#limit} characters long.',
      'string.max': 'Password can not be longer than {#limit} characters long.',
    }),
  confirmPassword: Joi.string().equal(Joi.ref('password')).messages({
    'any.only': 'Passwords must match.',
  }),
  postalCode: Joi.string()
    .regex(/^[ABCEGHJKLMNPRSTVXY]\d[A-Za-z][ -]?\d[A-Za-z]\d$/)
    .messages({
      'string.pattern.base': 'Must be a valid Canadian postal code.',
    }),
  healthCardNumber: Joi.string()
    .regex(/^\d{4}[\s.-]\d{3}[\s.-]\d{3}$/)
    .messages({
      'string.empty': 'Health card number is required.',
      'string.pattern.base': 'Must be a valid health care card number.',
    }),
  healthCardVersionNumber: Joi.string().regex(/[A-Z]/).length(2).messages({
    'string.pattern.base': 'Must be a valid version code.',
  }),
  healthCardExpiry: Joi.date().format('YYYY-MM-DD').min(moment().format('YYYY-MM-DD')).messages({
    'date.min': 'Health card must not be expired.',
  }),
  address: Joi.string()
    .regex(/^(\d)+\s([a-zA-Z])*\s?([a-zA-Z])*/)
    .messages({
      'string.pattern.base': 'Must be a valid address.',
    }),
  dateOfBirth: Joi.number().messages({
    'number.base': 'Must be a number.',
  }),
  city: Joi.string().min(2).max(30).messages({
    'string.min': 'City must be at least {#limit} characters.',
    'string.max': 'City can not be longer than {#limit} characters.',
    'string.empty': 'A city is required.',
  }),
  cvv: Joi.string()
    .regex(/^\d{3,4}$/)
    .messages({
      'string.empty': 'A CVC/CVV is required.',
      'string.pattern.base': 'Must be a valid CVC/CVV.',
    }),
  creditCardNumber: Joi.string()
    .regex(/^\d{13,19}$/)
    .messages({
      'string.empty': 'A credit card number is required.',
      'string.pattern.base': 'Must be a valid credit card number.',
    }),
  creditCardMonth: Joi.number()
    .min(parseInt(moment().format('MM'), 10) - 1)
    .messages({
      'number.min': 'Credit card can not be expired.',
      'number.base': 'A month is required.',
    }),
  creditCardYear: Joi.number().messages({
    'number.base': 'A year is required.',
  }),
  insuranceNumber: Joi.string()
    .regex(/^\d{8,10}$/)
    .allow(null, '')
    .messages({
      'string.pattern.base': 'Must be a valid insurance / member number.',
    }),
  groupPolicy: Joi.string()
    .regex(/^\d{5,7}$/)
    .allow(null, '')
    .messages({
      'string.pattern.base': 'Must be a valid group / policy number.',
    }),
  carrierId: Joi.string()
    .regex(/^\d{3}$/)
    .allow(null, '')
    .messages({
      'string.pattern.base': 'Must be a valid carried ID number.',
    }),
  medicationStrength: Joi.string()
    .regex(/(g)|(mg)$/)
    .allow('', null)
    .messages({
      'string.pattern.base': 'Medication strength must be in g or mg.',
    }),
  injectionDate: Joi.date().format('YYYY/MM/DD').allow('', null).messages({
    'date.format': 'Must be a valid date.',
  }),
  pharmacyName: Joi.string().min(1).messages({
    'string.empty': 'A pharmacy name is required.',
  }),
  pharmacyFax: Joi.string()
    .regex(/^(1[\s.-])?(\(?)\d{3}(\)?)[\s.-]\d{3}[\s.-]\d{4}$/)
    .messages({
      'string.pattern.base': 'Must be a valid fax number.',
      'string.empty': 'Fax number cannot be empty.',
    }),
  customCondition: Joi.string().min(1).messages({
    'string.min': 'Condition name can not be empty.',
    'string.empty': 'Condition name can not be empty.',
  }),
});

export default joiSchema;
