<template>
  <div class="theme-background">
    <!-- Title. -->
    <content-title :subtitle="true">
      <template slot="title">
        <h3>Allergies and Intollerances</h3>
      </template>
      <template slot="subtitle">
        <p>Please add any medical or other allergies or intollerances to your file.</p>
      </template>
    </content-title>

    <!-- Allergen table. -->
    <table class="allergy-table theme-table-two">
      <thead class="text-left">
        <tr class="theme-table-two">
          <th class="allergy-table-head theme-table-head-two">Allergy</th>
          <th class="allergy-table-head theme-table-head-two">
            <div style="float: left; margin-top: 10px">Reaction</div>
            <v-menu :close-on-content-click="false" v-model="menu">
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="float: right" icon v-bind="attrs" v-on="on" width="45px"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>

              <!-- Add record menu -->
              <div class="menu-popup theme-menu-popup">
                <v-form>
                  <h4 v-if="!editing">Add record</h4>
                  <h4 v-if="editing">Edit record</h4>
                  <custom-space></custom-space>
                  <span>Allergen</span><br />
                  <v-text-field
                    outlined
                    style="width: 200px"
                    placeholder="Type here"
                    v-model="allergyName"
                  ></v-text-field>
                  <span>Reactions</span><br />
                  <v-textarea
                    outlined
                    no-resize
                    rows="3"
                    style="width: 350px"
                    placeholder="Type here"
                    v-model="reaction"
                  ></v-textarea>
                  <div class="text-center">
                    <v-btn large rounded class="theme-button" @click="saveRecord()">Save</v-btn>
                  </div>
                </v-form>
              </div>
            </v-menu>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="agy in allergies" :key="agy.id" class="theme-table-two">
          <td>{{ agy.allergy }}</td>
          <td>
            <div>
              <div style="float: left; margin-top: 10px">{{ agy.reaction }}</div>

              <!-- Edit/Detele dropdown. -->
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="float: right"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    width="45px"
                    :style="Object.keys(agy).length === 0 ? { opacity: '0' } : { opacity: '1' }"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  >
                </template>
                <v-list>
                  <v-list-item-group>
                    <v-list-item>
                      <v-list-item-title @click="editRecord(agy.id)">Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="deleteRecord(agy.id)">Delete</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>
.allergy-table {
  max-width: 1000px;
  min-width: 100px;
  width: 80%;
}

.allergy-table-head {
  padding: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
</style>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import CustomSpace from './CustomSpace.vue';
import ContentTitle from './ContentTitle.vue';

export default {
  name: 'Allergies',
  components: {
    CustomSpace,
    ContentTitle,
  },
  data() {
    return {
      menu: false,
      lastId: 1,
      editIndex: 0,
      editing: false,
      allergyName: '',
      reaction: '',
      allergies: [
        {
          id: '1',
          allergy: 'Test',
          reaction: 'Test reaction.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions(['updateUser']),
    ...mapMutations(['saveUser']),
    saveRecord() {
      this.menu = false;
      if (this.editing) {
        this.editing = false;
        const toUpdate = this.allergies[this.editIndex];
        toUpdate.allergy = this.allergyName || 'Not provided.';
        toUpdate.reaction = this.reaction || 'Not provided.';

        this.allergyName = '';
        this.reaction = '';
      } else {
        this.lastId += 1;

        this.allergies.unshift({
          id: this.lastId,
          allergy: this.allergyName || 'Not provided.',
          reaction: this.reaction || 'Not provided.',
        });
      }
    },
    editRecord(id) {
      this.editing = true;
      this.menu = true;

      const index = this.getObjectIndexById(id);
      this.editIndex = index;
      this.allergyName = this.allergies[index].allergy;
      this.reaction = this.allergies[index].reaction;
    },
    deleteRecord(id) {
      const index = this.getObjectIndexById(id);

      this.allergies.splice(index, 1);
    },
    getObjectIndexById(id) {
      for (let i = 0; i < this.allergies.length; i += 1) {
        if (this.allergies[i].id === id) {
          return i;
        }
      }
      return undefined;
    },
    getLastId() {
      let id = 0;
      this.allergies.forEach((alg) => {
        if (alg.id > id) id = alg.id;
      });
      return id;
    },
    refresh() {
      if (this.user.metaData) {
        this.allergies = this.user.metaData.other.allergies;
        this.lastId = this.getLastId();
      }
    },
    save() {
      this.saveUser({
        metaData: {
          ...this.user.metaData,
          other: {
            ...this.user.metaData.other,
            allergies: this.allergies,
          },
        },
      });

      this.updateUser();
    },
    padTable(table) {
      if (table.length < 5) {
        for (let i = 5; i > table.length; i -= 1) {
          table.push({});
        }
      } else if (table.length >= 5) {
        for (let i = 5; i < table.length; i += 1) {
          if (Object.keys(table[i]).length === 0) {
            table.splice(i, 1);
          }
        }
      }
    },
  },
  mounted() {
    this.refresh();
  },
  watch: {
    allergies: {
      handler() {
        this.save();
        this.padTable(this.allergies);
      },
      deep: true,
    },
  },
};
</script>
