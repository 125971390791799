<template>
  <!-- Content header. -->
  <div class="content-header">
    <v-row justify="space-between" no-gutters>
      <v-col class="py-md-5 py-0">
        <div class="user-info mx-4 d-flex align-center" v-if="this.$vuetify.breakpoint.mdAndUp">
          <v-btn icon width="45px" class="swich-icon" style="float: left"
            ><v-icon style="cursor: pointer" x-large>mdi-account-circle</v-icon></v-btn
          >
          <div style="float: left" class="user-name ml-1">Welcome, {{ user.firstName }}</div>
        </div>
        <div v-else class="d-flex align-center px-4 justify-space-between">
          <logo-image height="86px" maxWidth="150px" />

          <v-btn icon v-if="this.$vuetify.breakpoint.smAndDown" @click="logout"
            ><v-icon>mdi-exit-to-app</v-icon></v-btn
          >
        </div>
      </v-col>
      <!-- <v-col cols="3" sm="6">
        <theme-button link="/booking" btnColor="blue" class="book">
          <v-icon left :style="$vuetify.breakpoint.xs ? 'margin-right: -5px' : 'margin-right: 10px'"
            >mdi-stethoscope</v-icon
          ><span class="d-none d-sm-block">Book a visit</span>
        </theme-button>
      </v-col> -->
    </v-row>
    <v-divider />
  </div>
</template>
<style scoped>
.user-info {
  white-space: nowrap;
}

.user-name {
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}

.book {
  float: right;
  border: none;
}
</style>
<script>
import { mapGetters } from 'vuex';
import { onLogout } from '../vue-apollo';

// import ThemeButton from './ThemeButton.vue';

export default {
  name: 'ContentHeader',
  components: {
    LogoImage: () => import('./LogoImage.vue'),
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    async logout() {
      const { tenantUrl } = { ...this.$store.state.user.info };
      await this.$store.commit('restoreSession');
      await onLogout();
      this.$router.push({ name: 'Login', params: { tenantUrl } });
    },
  },
};
</script>
