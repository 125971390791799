<template>
  <!-- Title for content containers -->
  <header class="content-title">
    <h1>
      <slot name="title"> </slot>
    </h1>
    <h2>
      <slot name="subtitle"></slot>
    </h2>
  </header>
</template>
<style lang="scss" scoped>
.content-title {
  margin-bottom: $spacer * 6;
}
h1 {
  font-size: 1em !important;
}
h2 {
  font-size: 0.8em;
  font-weight: normal;
}
</style>
<script>
export default {
  name: 'ContentTitle',
  props: ['subtitle'],
};
</script>
