<template>
  <div class="theme-background">
    <!-- Title. -->
    <content-title :subtitle="true">
      <template slot="title">
        <h3>Prescription</h3>
      </template>
      <template slot="subtitle">
        <p>Here you will find details for your prescriptions from the doctor from any visit.</p>
      </template>
    </content-title>

    <!-- Perscription record. -->
    <div style="max-width: 500px">
      <v-text-field
        @change="searchPrescriptions"
        outlined
        prepend-inner-icon="mdi-magnify"
        v-model="search"
        placeholder="Search my prescriptions"
      ></v-text-field>
      <h3 v-if="prescriptions.length === 0">You have no prescriptions from the doctor.</h3>
      <expandable-card :items="prescriptions">
        <template v-for="script in prescriptions" :slot="script.id + '-header'">
          <div :key="script.id + '-header'">
            <div style="float: left; max-width: 200px">
              <div>
                Date: <span>{{ script.date }}</span>
              </div>
              <custom-space></custom-space>
              <div>
                Practitioner: <span>{{ script.practitioner }}</span>
              </div>
            </div>
            <custom-space v-if="$vuetify.breakpoint.xs"></custom-space>
            <custom-space v-if="$vuetify.breakpoint.xs"></custom-space>
            <div
              :style="$vuetify.breakpoint.xs ? { float: 'left' } : { float: 'right' }"
              v-if="!script.status"
            >
              <v-icon class="prescription-warning theme-warning" large>mdi-alert-outline</v-icon>
            </div>
          </div>
        </template>
        <template v-for="script in prescriptions" :slot="script.id + '-content'">
          <div :key="script.id + '-content'">
            <div style="padding: 20px">
              <h4>Prescription details:</h4>
              <custom-space></custom-space>
              <ol>
                <li v-for="item in script.items" :key="item.id">
                  {{ item.name }}, {{ item.strength }}, {{ item.directions }}
                  <custom-space amt="2"></custom-space>
                </li>
              </ol>
              <custom-space></custom-space>
              <v-btn
                class="details theme-toggle-button"
                large
                contained
                elevation="3"
                to="/appointments"
                >Appointment Details</v-btn
              >
            </div>
            <div class="expandable-addon theme-addon">
              <div>
                <h4 style="max-width: 200px; float: left">Prescription Status</h4>
                <custom-space v-if="$vuetify.breakpoint.xs"></custom-space>
                <div :style="$vuetify.breakpoint.xs ? { float: 'left' } : { float: 'right' }">
                  <div v-if="script.status">
                    <h4 class="content-status">Sent</h4>
                    <v-icon large class="content-status-icon theme-success"
                      >mdi-check-outline</v-icon
                    >
                  </div>
                  <div v-if="!script.status">
                    <h4 class="content-status">Not Sent</h4>
                    <v-icon large class="content-status-icon theme-warning"
                      >mdi-alert-outline</v-icon
                    >
                  </div>
                </div>
              </div>
              <br />
              <custom-space></custom-space>
              <custom-space v-if="$vuetify.breakpoint.xs"></custom-space>
              <div>
                <p v-if="Object.keys(user.metaData.other.preferredPharmacy).length > 0">
                  {{ user.metaData.other.preferredPharmacy.name }}
                </p>
                <p
                  v-if="!(Object.keys(user.metaData.other.preferredPharmacy).length > 0)"
                  class="theme-warning"
                >
                  Please provide your preferred pharmacy information in your
                  <span class="theme-success">Settings</span> so we may send out your prescription.
                </p>
              </div>
              <p v-if="Object.keys(user.metaData.other.preferredPharmacy).length > 0">
                Contact your pharmacy for pickup times.
              </p>
              <v-btn
                v-if="!Object.keys(user.metaData.other.preferredPharmacy).length > 0"
                class="details theme-toggle-button"
                large
                contained
                to="/settings"
                >Pharmacy Settings</v-btn
              >
            </div>
          </div>
        </template>
      </expandable-card>
    </div>
  </div>
</template>
<style scoped>
.expandable-addon {
  padding: 20px;
  padding-left: 40px;
  margin-left: -25px;
  margin-right: -25px;
  margin-bottom: -17px;
  border: 1px solid;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.content-status-icon {
  margin-top: -5px;
}

.content-status {
  max-width: 100px;
  float: left;
  margin-right: 10px;
}

.prescription-warning {
  margin-right: 20px;
  margin-top: 5px;
}

@media all and (max-width: 600px) {
  .details {
    display: inline-block;
    padding: 20px !important;
    padding-top: 12px !important;
    word-wrap: break-word !important;
    white-space: normal !important;
    overflow: hidden;
  }
}
</style>
<script>
import { mapGetters } from 'vuex';
import CustomSpace from './CustomSpace.vue';
import ExpandableCard from './ExpandableCard.vue';
import ContentTitle from './ContentTitle.vue';

export default {
  name: 'Prescriptions',
  components: {
    ExpandableCard,
    CustomSpace,
    ContentTitle,
  },
  data() {
    return {
      search: '',
      prescriptions: [],
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    refresh() {
      if (this.user.metaData) {
        // Prescription attributes.
        // {
        //     id: '3',,
        //     status: true,
        //     practitioner: 'Test'
        //     date: '01-01-2020',
        //     items: [{
        //       id: '1',
        //       name: 'Test',
        //       strength: 'Test',
        //       directions: 'Test'
        //     }]
        // }

        // TODO: Test data.
        // this.prescriptions = this.user.metaData.other.prescriptions;
        this.prescriptions = [
          {
            id: '101',
            status: true,
            practitioner: 'a Test',
            date: '01-01-2020',
            items: [{ id: '1', name: 'Test', strength: 'Test', directions: 'Test' }],
          },
          {
            id: '102',
            status: false,
            practitioner: 'b Test',
            date: '01-01-2020',
            items: [{ id: '1', name: 'Test', strength: 'Test', directions: 'Test' }],
          },
        ];
      }
    },
    searchPrescriptions() {
      // Default sort.
      if (this.prescriptions.length > 1) {
        if (this.search === '') {
          this.prescriptions.sort((a, b) => {
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
          });
        } else {
          // String search.
          const sorted = [];
          const nonSorted = [];
          this.prescriptions.forEach((script) => {
            let matching = false;
            Object.keys(script).forEach((key) => {
              if (typeof script[key] === 'string' && script[key].includes(this.search)) {
                sorted.push(script);
                matching = true;
              }
            });
            if (!matching) nonSorted.push(script);
          });

          this.prescriptions = [...sorted, ...nonSorted];
        }
      }
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
