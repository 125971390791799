<template>
  <div>
    <content-header></content-header>
    <content-container>
      <tab-container :items="tabItems">
        <template v-for="tabItem in tabItems" :slot="tabItem.data">
          <component :is="tabItem.data" :key="tabItem.data"></component>
        </template>
      </tab-container>
    </content-container>
  </div>
</template>
<script>
import ContentContainer from '../components/ContentContainer.vue';
import ContentHeader from '../components/ContentHeader.vue';
import TabContainer from '../components/TabContainer.vue';
import Medications from '../components/Medications.vue';
import MedicalConditions from '../components/MedicalConditions.vue';
import Allergies from '../components/Allergies.vue';
import Prescriptions from '../components/Prescriptions.vue';
import OtherConditions from '../components/OtherConditions.vue';

export default {
  name: 'MedicalRecord',
  components: {
    ContentHeader,
    ContentContainer,
    TabContainer,
    Medications,
    MedicalConditions,
    Allergies,
    Prescriptions,
    OtherConditions,
  },
  data() {
    return {
      tabItems: [
        {
          title: 'Prescriptions',
          data: 'prescriptions',
        },
        {
          title: 'Medications',
          data: 'medications',
        },
        {
          title: 'Medical Conditions',
          data: 'medical-conditions',
        },
        {
          title: 'Allergies',
          data: 'allergies',
        },
        {
          title: 'Others',
          data: 'other-conditions',
        },
      ],
    };
  },
};
</script>
