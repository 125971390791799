<template>
  <!-- Expansion card with custom content. -->
  <v-expansion-panels multiple>
    <v-expansion-panel
      v-for="item in items"
      :key="item.id"
      :class="'panel ' + (item.completed ? 'theme-panel-one ' : 'theme-panel-two ')"
    >
      <v-expansion-panel-header>
        <slot :name="item.id + '-image'"></slot>
        <v-divider vertical class="panel-header-seperator"></v-divider>
        <slot :name="item.id + '-header'"></slot>
      </v-expansion-panel-header>
      <v-expansion-panel-content
        :class="'panel-content ' + (item.completed ? 'theme-panel-one ' : 'theme-panel-two ')"
      >
        <custom-space></custom-space>
        <slot :name="item.id + '-content'"></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<style scoped>
.panel {
  border-radius: 15px !important;
  margin: 10px !important;
  border-style: solid !important;
  border-width: 1px !important;
}

.panel-header-seperator {
  max-width: 1px;
  margin-top: -15px;
  margin-bottom: -15px;
  margin-right: 3%;
  margin-left: 20px;
}

.panel-content {
  border-top: 1px solid;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
</style>
<script>
import CustomSpace from './CustomSpace.vue';

export default {
  components: { CustomSpace },
  name: 'ExpandableCard',
  props: ['items'],
};
</script>
