<template>
  <!-- Container for tabbed content. -->
  <v-tabs
    class="tab"
    center-active
    centered
    grow
    right
    background-color="transparent !important"
    color="var(--v-info-base)"
  >
    <v-tab active-class="active-tab" v-for="item in items" :key="item.title">
      {{ item.title }}
    </v-tab>
    <v-tab-item v-for="item in items" :key="item.title" style="background-color: grey !important">
      <slot :name="item.data"></slot>
    </v-tab-item>
  </v-tabs>
</template>
<style scoped>
.active-tab {
  color: #000;
}
.tab {
  padding-top: 20px;
}
</style>
<script>
export default {
  name: 'TabContainer',
  props: ['items'],
};
</script>
