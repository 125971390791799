<template>
  <div class="theme-background">
    <!-- Title. -->
    <content-title :subtitle="true">
      <template slot="title">
        <h3>Other Medical Records</h3>
      </template>
      <template slot="subtitle">
        <p>
          You can add any immunizations, records, surgeries, forms or referals for the doctor to
          reference.
        </p>
      </template>
    </content-title>

    <!-- Other records and immunizations tables. -->
    <v-row>
      <v-col>
        <table class="otherrecords-table theme-table-two">
          <thead class="text-left">
            <tr class="theme-table-two">
              <th class="otherrecords-table-head theme-table-head-two" colspan="2">
                <div style="float: left; margin-top: 10px">Immunizations</div>
                <v-menu :close-on-content-click="false" v-model="imzMenu">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn style="float: right" icon v-bind="attrs" v-on="on" width="45px"
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                  </template>
                  <div class="menu-popup theme-menu-popup">
                    <v-form ref="imzForm">
                      <h4 v-if="!editingImz">Add record</h4>
                      <h4 v-if="editingImz">Edit record</h4>
                      <custom-space></custom-space>
                      <span>Injection type</span><br />
                      <v-text-field
                        outlined
                        style="width: 200px"
                        placeholder="Type here"
                        v-model="imzName"
                      ></v-text-field>
                      <custom-space></custom-space>
                      <span>Date injected</span><br />
                      <v-text-field
                        outlined
                        no-resize
                        rows="5"
                        style="width: 350px"
                        placeholder="YYYY/MM/DD"
                        v-model="imzDates[0]"
                        :rules="validate({ injectionDate: this.imzDates[0] })"
                      ></v-text-field>
                      <span>Date injected</span><br />
                      <v-text-field
                        outlined
                        no-resize
                        rows="5"
                        style="width: 350px"
                        placeholder="YYYY/MM/DD"
                        v-model="imzDates[1]"
                        :rules="validate({ injectionDate: this.imzDates[1] })"
                      ></v-text-field>
                      <span>Date injected</span><br />
                      <v-text-field
                        outlined
                        no-resize
                        rows="5"
                        style="width: 350px"
                        placeholder="YYYY/MM/DD"
                        v-model="imzDates[2]"
                        :rules="validate({ injectionDate: this.imzDates[2] })"
                      ></v-text-field>
                      <span>Date injected</span><br />
                      <v-text-field
                        outlined
                        no-resize
                        rows="5"
                        style="width: 350px"
                        placeholder="YYYY/MM/DD"
                        v-model="imzDates[3]"
                        :rules="validate({ injectionDate: this.imzDates[3] })"
                      ></v-text-field>

                      <div class="text-center">
                        <v-btn large rounded class="theme-button" @click="saveImz()">Save</v-btn>
                      </div>
                    </v-form>
                  </div>
                </v-menu>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="imz in immunizations" :key="imz.id" class="theme-table-one">
              <td>{{ imz.name }}</td>
              <td>
                <div>
                  <div style="float: left; margin-top: 10px">
                    {{ imz.dates ? `Dates: ${imz.dates.join(', ')}` : '' }}
                  </div>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        style="float: right"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        width="45px"
                        :style="Object.keys(imz).length === 0 ? { opacity: '0' } : { opacity: '1' }"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item>
                          <v-list-item-title @click="editImz(imz.id)">Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title @click="deleteImz(imz.id)">Delete</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col>
        <table class="otherrecords-table theme-table-one">
          <thead class="text-left">
            <tr class="theme-table-one">
              <th class="otherrecords-table-head theme-table-head-one" colspan="2">
                <div style="float: left; margin-top: 10px">Other records</div>
                <v-menu :close-on-content-click="false" v-model="otherMenu">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn style="float: right" icon v-bind="attrs" v-on="on" width="45px"
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                  </template>
                  <div class="menu-popup theme-menu-popup">
                    <v-form>
                      <h4 v-if="!editingOther">Add record</h4>
                      <h4 v-if="editingOther">Edit record</h4>
                      <custom-space></custom-space>
                      <span>Title</span><br />
                      <v-text-field
                        outlined
                        style="width: 200px"
                        placeholder="Type here"
                        v-model="otherName"
                      ></v-text-field>
                      <span>Description</span><br />
                      <v-textarea
                        outlined
                        no-resize
                        rows="3"
                        style="width: 350px"
                        placeholder="Type here"
                        v-model="otherDescription"
                      ></v-textarea>
                      <div class="text-center">
                        <v-btn large rounded class="theme-button" @click="saveOther()">Save</v-btn>
                      </div>
                    </v-form>
                  </div>
                </v-menu>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="other in otherRecords" :key="other.id" class="theme-table-one">
              <td>{{ other.name }}</td>
              <td>
                <div>
                  <div style="float: left; margin-top: 10px">{{ other.description }}</div>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        style="float: right"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        width="45px"
                        :style="
                          Object.keys(other).length === 0 ? { opacity: '0' } : { opacity: '1' }
                        "
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item>
                          <v-list-item-title @click="editOther(other.id)">Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title @click="deleteOther(other.id)"
                            >Delete</v-list-item-title
                          >
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.otherrecords-table {
  width: 100%;
}

.otherrecords-table-head {
  padding: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.add-otherrecords {
  padding: 20px;
  border-width: 1px;
}

td {
  border: none !important;
}

th {
  border-right: none !important;
}
</style>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import CustomSpace from './CustomSpace.vue';
import ContentTitle from './ContentTitle.vue';
import joiSchema from '../utils/joi';

export default {
  components: {
    CustomSpace,
    ContentTitle,
  },
  name: 'OtherConditions',
  data() {
    return {
      lastId: 2,
      editingImz: false,
      editingOther: false,
      editImzIndex: 0,
      editOtherIndex: 0,
      imzMenu: false,
      otherMenu: false,
      imzName: '',
      imzDates: [],
      otherName: '',
      otherDescription: '',
      immunizations: [],
      otherRecords: [],
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions(['updateUser']),
    ...mapMutations(['saveUser']),
    saveImz() {
      if (this.$refs.imzForm.validate()) {
        this.imzMenu = false;
        if (this.editingImz) {
          this.editingImz = false;
          const toUpdate = this.immunizations[this.editImzIndex];

          toUpdate.name = this.imzName || 'Not provided.';
          toUpdate.dates = [...this.imzDates].filter(Boolean);

          this.imzName = '';
          this.imzDates = [];
        } else {
          this.lastId += 1;

          this.immunizations.unshift({
            id: this.lastId,
            name: this.imzName || 'Not provided.',
            dates: [...this.imzDates].filter(Boolean),
          });
        }
      }
    },
    saveOther() {
      this.otherMenu = false;
      if (this.editingOther) {
        this.editingOther = false;
        const toUpdate = this.otherRecords[this.editOtherIndex];

        toUpdate.name = this.otherName || 'Not provided.';
        toUpdate.description = this.otherDescription || 'Not provided.';

        this.otherName = '';
        this.otherDescription = '';
      } else {
        this.lastId += 1;

        this.otherRecords.unshift({
          id: this.lastId,
          name: this.otherName || 'Not provided.',
          description: this.otherDescription || 'Not provided.',
        });
      }
    },
    editImz(id) {
      this.imzMenu = true;
      this.editingImz = true;

      const index = this.getObjectIndexByIdImz(id);

      this.editImzIndex = index;
      this.imzName = this.immunizations[index].name;
      this.imzDates = this.immunizations[index].dates;
    },
    editOther(id) {
      this.otherMenu = true;
      this.editingOther = true;

      const index = this.getObjectIndexByIdOther(id);

      this.editOtherIndex = index;
      this.otherName = this.otherRecords[index].name;
      this.otherDescription = this.otherRecords[index].description;
    },
    deleteImz(id) {
      const index = this.getObjectIndexByIdImz(id);

      this.immunizations.splice(index, 1);
    },
    deleteOther(id) {
      const index = this.getObjectIndexByIdOther(id);

      this.otherRecords.splice(index, 1);
    },
    getObjectIndexByIdImz(id) {
      for (let i = 0; i < this.immunizations.length; i += 1) {
        if (this.immunizations[i].id === id) {
          return i;
        }
      }
      return undefined;
    },
    getObjectIndexByIdOther(id) {
      for (let i = 0; i < this.otherRecords.length; i += 1) {
        if (this.otherRecords[i].id === id) {
          return i;
        }
      }
      return undefined;
    },
    validate(toValidate) {
      // Does not work as vuex action.
      const { error } = joiSchema.validate(toValidate);
      if (error) {
        return [error.message];
      }
      return [true];
    },
    refresh() {
      if (this.user.metaData) {
        this.immunizations = this.user.metaData.other.other.immunizations;
        this.otherRecords = this.user.metaData.other.other.otherRecords;
      }
    },
    save() {
      this.saveUser({
        metaData: {
          ...this.user.metaData,
          other: {
            ...this.user.metaData.other,
            immunizations: this.immunizations,
            otherRecords: this.otherRecords,
          },
        },
      });

      this.updateUser();
    },
    padTable(table) {
      if (table.length < 5) {
        for (let i = 5; i > table.length; i -= 1) {
          table.push({});
        }
      } else if (table.length >= 5) {
        for (let i = 5; i < table.length; i += 1) {
          if (Object.keys(table[i]).length === 0) {
            table.splice(i, 1);
          }
        }
      }
    },
  },
  mounted() {
    this.refresh();
  },
  watch: {
    immunizations: {
      handler() {
        this.save();
        this.padTable(this.immunizations);
      },
      deep: true,
    },
    otherRecords: {
      handler() {
        this.save();
        this.padTable(this.otherRecords);
      },
      deep: true,
    },
  },
};
</script>
