<template>
  <!-- Custom spacer for content positioning. -->
  <div>
    <div v-for="i in amount" :key="i" class="custom-spacer"></div>
  </div>
</template>
<style scoped>
.custom-spacer {
  height: 1.2em;
}
</style>
<script>
export default {
  name: 'CustomSpace',
  props: ['amt'],
  computed: {
    amount() {
      if (!this.amt) {
        return 1;
      }
      return parseInt(this.amt, 10);
    },
  },
};
</script>
